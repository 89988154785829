<template>
    <div class="graph widget">
        <div class="legend">
            <div
                v-for="(metric, i) in metricKeys"
                :key="`metric-${i+1}`">
                <span :class="`metric-${i+1}`"><span class="metric-name">{{ metrics[metric].name }}</span></span>
            </div>
        </div>
        <apex-chart
            ref="chart"
            chart="line"
            :height="chartHeight"
            :options="options"
            :series="series" />
    </div>
</template>

<script>
import ApexChart from 'vue-apexcharts';
export default {
    name: 'Graph',
    components: {
        ApexChart
    },
    props: {
        metrics: {
            type: Object,
            required: true
        },
        size: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            options: {
                chart: {
                    id: 'metric',
                    type: 'line',
                    toolbar: {
                        show: false
                    },
                    dataLabels: {
                        enabled: true
                    },
                    height: 300
                },
                colors: ['#7FDDFE', '#E350B4'],
                xaxis: {
                    type: 'datetime',
                    labels: {
                        trim: true,
                        style: {
                            colors: '#8f9ea6'
                        }
                    }
                },
                grid: {
                    borderColor: '#929292',
                    strokeDashArray: 4,
                },
                stroke: {
                    width: 2
                },
                markers: {
                    size: 3,
                    colors: ['#FFFFFF', '#FFFFFF'],
                    strokeColors: ['#7FDDFE', '#E350B4'],
                    strokeWidth: 2,
                    fillOpacity: 0
                },
                legend: {
                    show: false
                },
                yaxis: [
                    {
                        labels: {
                            style: {
                                color: '#8f9ea6'
                            },
                            formatter: (val) => {
                                if(val > 1000) {
                                    return (val/1000) + 'k';
                                } else {
                                    return Math.round(val);
                                }
                            }
                        },
                    },
                ],
            }
        };
    },
    computed: {
        chartHeight() {
            switch(this.size) {
                case 'full':
                    return 550;
                default:
                    return 250;
            }
        },
        metricKeys() {
            return Object.keys(this.metrics);
        },
        series() {
            return this.metricKeys.map(key => {
                return {
                    name: this.metrics[key].name,
                    data: this.metrics[key].series
                };
            });
        }
    },
    created() {
        if(this.metricKeys.length == 2) {
            this.options.yaxis.push(
                {
                    opposite: true,
                    labels: {
                        style: {
                            color: '#8f9ea6'
                        },
                        formatter: (val) => {
                            if(val > 1000) {
                                return (val/1000) + 'k';
                            } else {
                                return Math.round(val);
                            }
                        }
                    }
                }
            );
        }
    },
    methods: {
        setYAxis() {
            if(this.metricKeys.length == 2 && this.options.yaxis.length == 1) {
                this.options.yaxis.push(
                    {
                        opposite: true,
                        labels: {
                            style: {
                                color: '#8f9ea6'
                            },
                            formatter: (val) => {
                                if(val > 1000) {
                                    return (val/1000) + 'k';
                                } else {
                                    return Math.round(val);
                                }
                            }
                        }
                    }
                );
            } else {
                this.options.yaxis = [
                        {
                            labels: {
                                style: {
                                    color: '#8f9ea6'
                                },
                                formatter: (val) => {
                                    if(val > 1000) {
                                        return (val/1000) + 'k';
                                    } else {
                                        return Math.round(val);
                                    }
                                }
                            },
                        },
                    ];
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.chart-title {
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    color: $gray;
}
.legend {
    display: flex;
    justify-content: center;
    transform: translateY(15px);
    .metric-1,
    .metric-2 {
        display: block;
        white-space: nowrap;
        font-size: 11px;
        margin-right: 30px;
        position: relative;
        .metric-name::after {
            content: '';
            display: block;
            position: absolute;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #7EDDFE;
            top: 3px;
            left: -15px;
        }
    }
    .metric-2 .metric-name::after {
        background: #E350B4;
    }
}
</style>